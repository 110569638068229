import React from "react"
import { Modal, Button } from "reactstrap"
import { ModalHeader, InlineError, Loader } from "components"

export const CautionModal = ({
  isOpen = true,
  loading,
  toggle,
  heading,
  text,
  buttonText,
  onButtonClick,
  showCancel = true,
  error,
  showNotice = true,
  showCautionIcon = true,
  className = "modal-dialog-centered u-modal__w-30rem",
}) => (
  <Modal isOpen={isOpen} className={className}>
    <div className="p-4">
      <ModalHeader title={heading} toggle={toggle} />
      {loading ? (
        <div className="position-relative p-5">
          <Loader />
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center">
          <div className="mt-4 d-flex justify-content-between">
            {showCautionIcon && (
              <i className="fa fa-info-circle u-caution-icon mr-4" />
            )}
            <div>
              {showNotice && (
                <div className="text-primary mb-3">IMPORTANT NOTICE</div>
              )}
              <div>{text}</div>
            </div>
          </div>
          <Button color="primary" className="mt-5 w-50" onClick={onButtonClick}>
            {buttonText}
          </Button>
          {showCancel && (
            <span
              className="text-primary p-3 pl-5 pr-5 mt-2 cursor-pointer"
              onClick={toggle}>
              Cancel
            </span>
          )}
          <InlineError text={error} />
        </div>
      )}
    </div>
  </Modal>
)
