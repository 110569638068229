import React from "react"
import { Modal, Button } from "reactstrap"

export const SuccessModal = ({
  isOpen,
  toggle,
  heading,
  text,
  buttonText = "Dismiss",
  className = "modal-dialog-centered u-modal__w-30rem",
}) => (
  <Modal isOpen={isOpen} className={className}>
    <div className="d-flex flex-column justify-content-between align-items-center p-5">
      <div className="c-success-modal__icon-container">
        <i className="fa fa-check" />
      </div>
      <h1 className="mt-3">{heading}</h1>
      <span className="mt-3 text-center">{text}</span>
      <Button color="primary" className="mt-5" onClick={toggle}>
        {buttonText}
      </Button>
    </div>
  </Modal>
)
