import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import { removeDeletedUsersFromAuth0Mutation } from "domains/accountSettings/graphql"
import { SettingItem } from "domains/accountSettings/components"
import { CautionModal, SuccessModal } from "components"

export const RemoveUsersFromAuth0 = () => {
  const [showSuccessModal, setShowSuccessModal] = useState()
  const [showRemoveUserModal, setShowRemoveUserModal] = useState()
  const [showErrorModal, setShowErrorModal] = useState()

  const [
    removeDeletedUsersFromAuth0,
    { data, loading: removingUsers, error: errorRemovingUsers },
  ] = useMutation(removeDeletedUsersFromAuth0Mutation, {
    onCompleted: () => {
      setShowSuccessModal(true)
      setShowRemoveUserModal(false)
      setShowErrorModal(false)
    },
    onError: () => {
      setShowErrorModal(true)
      setShowRemoveUserModal(false)
    },
  })

  const removedUsers = (data && data.removeDeletedUsersFromAuth0) || []

  const successMessage = (
    <div>
      The following Auth0 ID's were successfully removed:
      <br />
      <br />
      {formatUsers(removedUsers)}
    </div>
  )

  let failureMessage

  if (errorRemovingUsers?.message === "too-many-deleted-users") {
    const usersToBeDeleted =
      errorRemovingUsers?.graphQLErrors?.[0]?.details?.usersToBeDeleted || []

    failureMessage = {
      heading: "Too many users to be deleted",
      text: (
        <div>
          <div>Are you sure you want to delete these users from Auth0?</div>
          <br />
          {formatUsers(usersToBeDeleted)}
        </div>
      ),
      buttonText: "Yes, Delete",
      showCancel: true,
      onButtonClick: () =>
        removeDeletedUsersFromAuth0({ variables: { force: true } }),
    }
  } else if (errorRemovingUsers?.message === "error-deleting-auth0-ids") {
    const erroredUsers =
      errorRemovingUsers?.graphQLErrors?.[0].details.errors || []

    failureMessage = {
      heading: "Failed to delete all users",
      text: (
        <div>
          <div>
            An error occurred when trying to delete the following users from
            Auth0:
          </div>
          <br />
          {formatUsers(erroredUsers)}
        </div>
      ),
      buttonText: "OK",
      onButtonClick: () => {
        setShowErrorModal(false)
      },
      showCancel: false,
    }
  } else {
    failureMessage = {
      heading: "Failed to delete users",
      text: (
        <div>
          <div>An error occurred when deleting users from Auth0</div>
          <br />
          <code>{errorRemovingUsers?.message}</code>
        </div>
      ),
      buttonText: "OK",
      onButtonClick: () => {
        setShowErrorModal(false)
      },
      showCancel: false,
    }
  }

  return (
    <>
      <SettingItem
        onButtonClick={() => setShowRemoveUserModal(true)}
        buttonText="Remove from Auth0">
        Remove deleted users from Auth0
      </SettingItem>
      {showRemoveUserModal && (
        <CautionModal
          isOpen
          loading={removingUsers}
          toggle={() => setShowRemoveUserModal(false)}
          heading="Remove users from Auth0"
          text="Are you sure you want remove all deleted users from Auth0?"
          buttonText="Yes, Proceed"
          onButtonClick={removeDeletedUsersFromAuth0}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          className="modal-dialog-centered"
          isOpen
          toggle={() => setShowSuccessModal(false)}
          heading="Success"
          text={successMessage}
        />
      )}
      {showErrorModal && (
        <CautionModal
          className="modal-dialog-centered"
          isOpen
          toggle={() => {
            setShowErrorModal(false)
            setShowRemoveUserModal(false)
          }}
          onButtonClick={failureMessage.onButtonClick}
          buttonText={failureMessage.buttonText}
          showCancel={failureMessage.showCancel}
          heading={failureMessage.heading}
          text={failureMessage.text}
        />
      )}
    </>
  )
}

const formatUsers = users => {
  return users.map(({ auth0Id, email, errorDetails }) => (
    <>
      <code key={auth0Id}>
        {auth0Id} - {email} {errorDetails ? `- ${errorDetails}` : ""}
      </code>
      <br />
    </>
  ))
}
